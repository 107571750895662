@import "~bootstrap/scss/bootstrap";


#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  > .page {
    flex: 1;
    display: flex;
    flex-direction: column;

    .waiting {
      width: 4em;
      height: 4em;
    }

    .debugTreeComponent {

      border-top: 2px solid #000;
      padding: 10px; /* Optional: Adds some space inside the container */

      bottom: 0; /* Aligns the child component to the bottom of the parent container */
      width: 100%; /* Optional, depending on your layout needs */
    }
    
  }

  > .footer {
    background: #f5f5f5;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    bottom: 0;
  }
}
